import LocalStorage from '../util/LocalStorage';
import Logger from '../util/Logger';
import { dispatchCustomEvent } from '../util/dispatchCustomEvent';
import { getSDK } from '../client/globals';
import { getProxySDK } from '../client/globals';
import { _configuration } from '../client/symbols';
import navigateToPath from './helpers/navigateToPath';

const MAX_WAIT_TIME_MS = 30000;

let baseUrl: string | undefined;

export const setBaseURL = (url: string | undefined) => {
  baseUrl = url;
};

export const getBaseURL = () => {
  if (baseUrl) {
    return baseUrl;
  }

  let url = getSDK()?.[_configuration]?.api;

  if (url === undefined) {
    const override = new URL(window.location.href).searchParams.get('api');
    if (!!override) {
      url = override;
    } else {
      url = `${process.env.REACT_APP_API_URL}`;
    }
  }

  Logger.blue('[target]', url);
  return url;
};

const frame = process.env.REACT_APP_FRAME_NAME ?? '';
const isInjected = ['CommandBar', 'Proxy'].includes(frame);

export const getRefreshToken = () => {
  if (isInjected) {
    return (window as any)?.CommandBarProxy?._refresh;
  } else {
    return LocalStorage.get('refresh', '');
  }
};

export const getAccessToken = () => {
  if (isInjected) {
    return (window as any)?.CommandBarProxy?._access;
  } else {
    return LocalStorage.get('access', '');
  }
};

export const setTokens = ({ refresh, access }: { refresh?: string; access?: string }) => {
  if (isInjected) {
    if (refresh !== undefined) (window as any).CommandBarProxy._refresh = refresh;
    if (access !== undefined) (window as any).CommandBarProxy._access = access;
  } else {
    if (refresh !== undefined) LocalStorage.set('refresh', refresh);
    if (access !== undefined) LocalStorage.set('access', access);
  }
};

export const removeTokens = () => {
  if (isInjected) {
    delete (window as any)?.CommandBarProxy?._refresh;
    delete (window as any)?.CommandBarProxy?._access;
  } else {
    LocalStorage.remove('access');
    LocalStorage.remove('refresh');
  }
};

const maybeRedirectToLoginPage = () => {
  const isLoginPathname = window.location.pathname.startsWith('/login');

  if (!isInjected && !isLoginPathname) {
    navigateToPath('/login' + window.location.search);
  }
};

const getAuthorizationHeader = () => {
  const token = getAccessToken();

  if (!!token) {
    return `JWT ${token}`;
  } else {
    return null;
  }
};

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
  accept: 'application/json',
};

export type Response<T> = {
  data: T;
  status: number;
  statusText: string;
  headers: Record<string, string>;
};

type Options = {
  signal?: AbortSignal;
  headers?: Record<string, string>;
};

const assertNotAirgapped = () => {
  const airgap = getProxySDK()[_configuration]?.airgap;
  if (airgap) {
    Logger.red('blocking unexpected request in airgapped mode');
    throw new Error('unexpected request in airgapped mode');
  }
};

const isRetryable = ({ method, path }: { method: string; path: string }) => {
  const shouldRetryForMethod = method.toLowerCase() === 'get';
  const shouldRetryForEndpoint =
    ['commands', 'categories', 'placeholders', 'settings', 'contexts'].includes(path) || path.includes('/config/');

  return shouldRetryForMethod && shouldRetryForEndpoint;
};

const refreshAuthToken = async (): Promise<boolean> => {
  const refreshToken = getRefreshToken();

  if (!refreshToken) {
    removeTokens();
    maybeRedirectToLoginPage();
    return false;
  }

  const tokenParts = JSON.parse(window.atob(refreshToken.split('.')[1]));

  // exp date in token is expressed in seconds, while now() returns milliseconds:
  const now = Math.ceil(Date.now() / 1000);

  if (tokenParts.exp <= now) {
    Logger.warn('Refresh token is expired', tokenParts.exp, now);
    removeTokens();
    maybeRedirectToLoginPage();
    return false;
  }

  // refresh auth tokens
  const authTokenRefreshResponse = await fetch(`${getBaseURL()}/auth/refresh/`, {
    method: 'POST',
    headers: { ...DEFAULT_HEADERS },
    body: JSON.stringify({ refresh: refreshToken }),
  });

  Logger.blue('@ RESET TOKENS');
  const data = await authTokenRefreshResponse.json();

  setTokens({ access: data.access, refresh: data.refresh });

  if (!isInjected) {
    // Notify of changes to tokens
    try {
      dispatchCustomEvent('CB_EDITOR_SYNC', { detail: {} });
    } catch (err) {}
  }

  return true;
};

export const put = <T = any>(url: string, data: string | object | undefined = undefined, options: Options = {}) =>
  _fetch<T>('PUT', url, data, options);

export const post = <T = any>(url: string, data: string | object | undefined = undefined, options: Options = {}) =>
  _fetch<T>('POST', url, data, options);

export const get = <T = any>(url: string, options: Options = {}) => _fetch<T>('GET', url, undefined, options);

export const patch = <T = any>(url: string, data: string | object | undefined = undefined, options: Options = {}) =>
  _fetch<T>('PATCH', url, data, options);

export const del = <T = any>(url: string, data: string | object | undefined = undefined, options: Options = {}) =>
  _fetch<T>('DELETE', url, data, options);

const _fetch = async <T>(
  method: string,
  path: string,
  data: string | object | undefined,
  options: Options = {},
  numRetries = 5,
): Promise<Response<T>> => {
  assertNotAirgapped();

  // convert data to string
  if (data !== undefined && typeof data !== 'string') {
    data = JSON.stringify(data);
  }

  let _baseURL = getBaseURL();

  // Redirect `/t/` posts to a separate server
  if (path.endsWith('/t/') && ['https://api.commandbar.com'].includes(_baseURL)) {
    _baseURL = 'https://t.commandbar.com';
  }

  let json: any, response: globalThis.Response, responseHeaders: Record<string, string>;

  // remove leading / from path
  while (path.startsWith('/')) path = path.slice(1);

  let n = 0;
  do {
    const authorizationHeader = getAuthorizationHeader();

    response = await fetch(_baseURL + '/' + path, {
      method,
      headers: {
        ...DEFAULT_HEADERS,
        ...options.headers,
        ...(authorizationHeader ? { authorization: authorizationHeader } : {}),
      },
      body: data,
      signal: options.signal,
    });

    responseHeaders = Object.fromEntries(response.headers.entries());

    let shouldRetry = false;
    if (!response.ok) {
      if (response.status === 401) {
        if (!(await refreshAuthToken())) break;
        shouldRetry = true;
      } else if (isRetryable({ method, path })) {
        shouldRetry = true;
      } else {
        // emulates existing error behavior, see https://github.com/tryfoobar/monobar/blob/e118b37ec4284ab8cb2b1a740e0b03559c526a57/internal/src/middleware/network.ts#L254
        try {
          let data = await response.text();

          try {
            data = JSON.parse(data);
          } catch {}

          return Promise.reject(data || 'Something went wrong');
        } catch (e) {
          // eslint-disable-next-line no-throw-literal
          throw 'Something went wrong.';
        }
      }
      json = null;
    } else {
      if (response.status === 204) json = null;
      else if (response.headers.get('content-length') === '0') json = null;
      else json = await response.json();
    }

    if (!shouldRetry) break;

    // Try again after a delay.
    //
    // Either the auth token was successfully refreshed,
    // --or-- the "access" token was removed from localstorage
    // and the request, when retried, will be retried without auth
    //
    // eslint-disable-next-line no-loop-func
    await new Promise((resolve) =>
      // wait 2^n * 100ms + random jitter up to 1s, up to a max of 30s
      setTimeout(resolve, Math.min(Math.pow(2, n) * 100 + Math.floor(Math.random() * 1000), MAX_WAIT_TIME_MS)),
    );
    n++;
  } while (n <= numRetries);

  return {
    data: json,
    status: response.status,
    statusText: response.statusText,
    headers: responseHeaders,
  };
};
