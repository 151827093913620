export const COMMANDS_ROUTE = '/commands';
export const RECORDS_ROUTE = '/records';
export const RELEASES_ROUTE = '/releases';
export const NUDGE_ROUTE = '/nudges';
export const NUDGE_TAB_ROUTE = '/nudges/nudges';
export const NUDGE_SETTINGS_ROUTE = '/nudges-settings';
export const CHECKLIST_ROUTE = '/questlists';
export const AUDIENCES_ROUTE = '/audiences';

export const HELPHUB_PARENT_ROUTE = '/help-hub';
export const HELPHUB_ROUTE = '/help-hub/overview';
export const INSTANT_ANSWERS_ROUTE = '/help-hub/instant-answers';
export const HELPHUB_SETTINGS_ROUTE = '/help-hub/settings';
export const HELPHUB_RECOMMENDATIONS_ROUTE = '/help-hub/recommendations';

export const SETTINGS_ROUTE = '/settings';
export const DESIGN_ROUTE = '/design';

export const TOOLS_PARENT_ROUTE = '/tools';
export const DEBUGGER_ROUTE = '/tools/debug';
export const HISTORY_ROUTE = '/tools/history';

export const ALL_EDITOR_ROUTES = [
  COMMANDS_ROUTE,
  RECORDS_ROUTE,
  RELEASES_ROUTE,
  NUDGE_ROUTE,
  CHECKLIST_ROUTE,
  AUDIENCES_ROUTE,
  DEBUGGER_ROUTE,
  HELPHUB_PARENT_ROUTE,
  HELPHUB_ROUTE,
  INSTANT_ANSWERS_ROUTE,
  SETTINGS_ROUTE,
  HISTORY_ROUTE,
  DESIGN_ROUTE,
  TOOLS_PARENT_ROUTE,
] as const;

export const widgetRoutes = [HELPHUB_PARENT_ROUTE, COMMANDS_ROUTE, NUDGE_ROUTE, CHECKLIST_ROUTE];

type EDITOR_ROUTE = (typeof ALL_EDITOR_ROUTES)[number];

export const isValidEditorPage = (page: string | null): page is EDITOR_ROUTE => {
  if (!page) return false;
  return !!ALL_EDITOR_ROUTES.find((route: string) => page.includes(route));
};

const getEditorPageRoot = (page: string | null): EDITOR_ROUTE | undefined => {
  return ALL_EDITOR_ROUTES.find((route: string) => page?.startsWith(route));
};

export const getEditorInitialEntries = (page: string | null): string[] => {
  if (page && isValidEditorPage(page)) {
    const root = getEditorPageRoot(page);
    if (root && root !== page) return [root, page];

    return [page];
  }

  return ['/'];
};

export const DEFAULT_EDITOR_ROUTE = COMMANDS_ROUTE;
