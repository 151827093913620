import * as t from 'io-ts';

import * as generics from './generics';
import { CommandCategoryV } from './commandCategory';
import { ContextV } from './context';
import { GuideV } from './guide';
import { EndUserV } from './helpers/endUser';
import { OrganizationV, defaults } from './OrganizationV';
import { PlaceholderV } from './placeholder';
import { decodeThrowing, decodeToPromise } from './generics';
import { NamedRuleV } from './helpers/rules';
import { DashboardFlagsPartialV, DashboardFlagsV } from './dashboardFlags';
import { CommandV, EditorCommandLiteV, EditorCommandV } from './command';
import * as axiosInstance from './network';

export { ResourceSettingsV, ResourceSettingsByContextKeyV } from './ResourceSettingsV';
export { OrganizationV, unknownOrganization } from './OrganizationV';

export const OrganizationStatusV = t.type({
  id: t.union([t.number, t.string]),
  uuid: t.string,
  errors_72h: t.number,
  suggestions_72h: t.number,
  executions_total: t.number,
  users_total: t.number,
});

export const KeyEventV = t.union([
  t.literal('Client-Error'),
  t.literal('Command execution'),
  t.literal('Command suggestion'),
  t.literal('Abandoned search'),
  t.literal('Search miss'),
]);

// type of map of string to string for integration settings
export const InternalSettingsV = t.type({
  id: t.string,
  integrations: t.record(
    t.string,
    t.record(
      t.string,
      t.union([
        t.string,
        t.boolean,
        t.record(t.string, t.union([t.string, t.boolean, t.record(t.string, t.string), t.array(t.string)])),
      ]),
    ),
  ),
});

export const read = generics.readObject(OrganizationV, 'organizations');
export const update = generics.updateObject(OrganizationV, OrganizationV, 'organizations');
export const listCommands = (orgUID: string) =>
  generics.listObject(EditorCommandLiteV, `organizations/${orgUID}/commands_lite`)();

export const getCommandByUrl = async (orgUID: string, url: string) => {
  const result = await axiosInstance.get(`organizations/${orgUID}/commands/by_url/${encodeURIComponent(url)}`);

  return await decodeToPromise(CommandV, result.data);
};

export const listAnswers = (orgUID: string) => generics.listObject(EditorCommandV, `organizations/${orgUID}/answers`)();
export const listCommandCategories = (orgUID: string) =>
  generics.listObject(CommandCategoryV, `organizations/${orgUID}/categories`)();
export const listGuides = (orgUID: string) => generics.listObject(GuideV, `organizations/${orgUID}/guides`)();
export const listContexts = (orgUID: string) => generics.listObject(ContextV, `organizations/${orgUID}/contexts`)();
export const listPlaceholders = (orgUID: string) =>
  generics.listObject(PlaceholderV, `organizations/${orgUID}/placeholders`)();
export const listRules = (orgUID: string) => generics.listObject(NamedRuleV, `organizations/${orgUID}/rules`)();
export const userHasAccess = (uuid: string, user: string) => {
  const func = generics.readObjectDetail(EndUserV, 'organizations', 'end_users');
  return func(uuid, { user: encodeURIComponent(user) });
};

export const readHealth = generics.readObjectDetail(OrganizationStatusV, 'organizations', 'health');

export const readInternal = generics.readObjectDetail(InternalSettingsV, 'organizations', 'internal');
export const updateInternal = generics.updateObjectDetail(
  InternalSettingsV,
  InternalSettingsV,
  'organizations',
  'internal',
);

export const readDashboardFlags = generics.read(DashboardFlagsV, 'dashboard-flags');
export const updateDashboardFlags = generics.update(DashboardFlagsV, DashboardFlagsPartialV, 'dashboard-flags');

export const decode = (data: any) => decodeThrowing(OrganizationV, { ...defaults, ...data });
