export type PlatformType = 'mac' | 'ios' | 'windows' | 'android' | 'linux';
export type BrowserType = 'chrome' | 'firefox' | 'safari' | 'opera' | 'edge' | 'brave' | 'arc' | 'ie10' | 'ie11';
export type DeviceType = 'mobile' | 'desktop';

export const isMobile = () => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true;
  }
  return false;
};

export const getDeviceType = (): DeviceType => {
  if (isMobile()) {
    return 'mobile';
  }

  return 'desktop';
};

export const getBrowser = (): BrowserType | null => {
  const userAgent = navigator.userAgent;

  if (!!navigator.brave?.isBrave) {
    return 'brave';
  } else if (getComputedStyle(document.documentElement).getPropertyValue('--arc-palette-background')) {
    return 'arc';
  } else if (userAgent.match(/msie/i)) {
    return 'ie10';
  } else if (userAgent.match(/trident/i)) {
    return 'ie11';
  } else if (userAgent.match(/edg/i)) {
    return 'edge';
  } else if (userAgent.match(/opr\//i)) {
    return 'opera';
  } else if (userAgent.match(/chrome|chromium|crios/i)) {
    return 'chrome';
  } else if (userAgent.match(/firefox|fxios/i)) {
    return 'firefox';
  } else if (userAgent.match(/safari/i)) {
    return 'safari';
  }

  return null;
};

// https://stackoverflow.com/a/38241481
export const getOperatingSystem = (): PlatformType => {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

  // default to Mac
  let os: PlatformType = 'mac';

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'mac';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'ios';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'windows';
  } else if (/Android/.test(userAgent)) {
    os = 'android';
  } else if (/Linux/.test(platform)) {
    os = 'linux';
  }

  return os;
};

export const getTriggerKey = (event: any) => {
  if (['windows', 'android', 'linux'].includes(getOperatingSystem())) {
    return event.ctrlKey;
  } else {
    return event.metaKey;
  }
};

export const osControlKey = (suffix?: string) => {
  if (['windows', 'android', 'linux'].includes(getOperatingSystem())) {
    if (!!suffix) {
      return `Ctrl+${suffix}`;
    }
    return 'Ctrl';
  } else {
    if (!!suffix) {
      return `⌘${suffix}`;
    }
    return '⌘';
  }
};
