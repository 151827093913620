import * as t from 'io-ts';
import { ResourceSettingsByContextKeyV } from './ResourceSettingsV';
import { LabeledActionV } from './helpers/actions';
import { IOrganizationType } from './types';

const OrganizationBaseV = t.intersection(
  [
    t.type({
      id: t.union([t.number, t.string]),
      name: t.string,
      created: t.string,
    }),
    t.partial({}),
  ],
  'OrganizationBase',
);

const OrganizationAdditionalV = t.type({
  launcher_type: t.union([t.literal('minimal'), t.literal('alternate'), t.literal('prompt'), t.literal('none')]),
  launcher_position: t.union([
    t.literal('topRight'),
    t.literal('topLeft'),
    t.literal('bottomRight'),
    t.literal('bottomLeft'),
  ]),
  show_launcher_recommendations: t.boolean,
  recommendations_type: t.union([t.literal('None'), t.literal('Custom'), t.literal('Algorithm'), t.undefined]), // DEPRECATED
  launcher_offset_x: t.number,
  launcher_offset_y: t.number,
  helphub_launcher_offset_x: t.number,
  helphub_launcher_offset_y: t.number,
  helphub_launcher_anchor: t.string,
  helphub_launcher_position: t.union([
    t.literal('topRight'),
    t.literal('topLeft'),
    t.literal('bottomRight'),
    t.literal('bottomLeft'),
  ]),
  helphub_launcher_type: t.union([
    t.literal('bookOpen'),
    t.literal('graduationHat'),
    t.literal('bookClosed'),
    t.literal('bookmark'),
    t.literal('chatCircle'),
    t.literal('askAI'),
    t.literal('custom'),
    t.literal('none'),
  ]),
  base_url: t.string,
  theme: t.object,
  icon: t.string,
  icon_suggest: t.string,
  icon_tutorial: t.string,
  icon_go_forward: t.string,
  chat_system_prompt_modifier: t.string,
  helphub_chat_welcome_message: t.string,
  helphub_chat_avatar: t.string,
  resource_options: ResourceSettingsByContextKeyV,
  should_show_onboarding: t.boolean,
  last_snippet_request: t.union([t.string, t.null]),
  last_snippet_request_in_production: t.union([t.string, t.null]),
  branding: t.string,
  custom_call_to_action: t.string, // DEPRECATED
  search_fuzzy_threshold: t.union([t.number, t.null]),
  show_skin_editor: t.boolean,
  allow_event_handlers: t.boolean,
  in_bar_feedback: t.boolean,
  summon_hotkey_override: t.union([t.string, t.null]),
  end_user_hotkeys: t.union([t.undefined, t.boolean]),
  force_end_user_identity_verification: t.boolean,
  force_end_user_identity_verification_for_helphub: t.boolean,
  end_user_shortcuts_enabled: t.boolean,
  end_user_recents_enabled: t.boolean,
  releases_available: t.boolean,
  releases_enabled: t.boolean,
  fallback_commands: t.array(t.number),
  event_attribute_block_list: t.array(t.string),
  tab_direction: t.union([t.literal('horizontal'), t.literal('vertical')]),
  recommended_tab_enabled: t.boolean,
  recents_tab_enabled: t.boolean,
  recommended_tab_instruction: t.string,
  recents_tab_instruction: t.string,
  hotload_help_docs: t.boolean,
  slash_filters_enabled: t.boolean,
  recents_sort_key: t.union([t.number, t.null]),
  recommended_sort_key: t.union([t.number, t.null]),
  show_suggested_synonyms: t.boolean,
  has_hotloaded_help_docs: t.boolean,
  bar_enabled: t.boolean,
  in_bar_doc_search: t.boolean,
  featured_item_cards: t.boolean,
  helphub_ai_enabled: t.boolean,
  helphub_suggested_queries_enabled: t.boolean,
  helphub_continuations_enabled: t.boolean,
  helphub_chat_only_mode: t.boolean,
  helphub_enabled: t.boolean,
  helphub_chat_fallback_message: t.union([t.string, t.null]),
  helphub_chat_fallback_actions: t.array(LabeledActionV),
  helphub_manual_suggested_queries: t.array(t.string),
  copilot_experiences_enabled: t.boolean,
  nudge_rate_limit: t.union([t.null, t.number]),
  bar_hide_completed_nudges_questlists: t.boolean,
  nudge_rate_period: t.union([t.literal('day'), t.literal('week'), t.literal('session')]),
  installed_at: t.union([t.string, t.null]),
  integrations: t.partial({
    algolia: t.type({
      indexes: t.record(t.string, t.union([t.string, t.record(t.string, t.string)])),
      applicationID: t.union([t.string, t.undefined]),
      apiKey: t.union([t.string, t.undefined]),
    }),
    heap: t.type({
      segments: t.record(t.string, t.string),
      send_events_to_heap: t.boolean,
    }),
    slack: t.type({
      channelId: t.string,
      configuration: t.type({
        analytic_events: t.array(t.string),
      }),
    }),
  }),
});

export const defaults: t.TypeOf<typeof OrganizationAdditionalV> = {
  allow_event_handlers: true,
  branding: '',
  base_url: '',
  custom_call_to_action: '',
  icon: 'iconic',
  icon_suggest: '',
  icon_tutorial: '',
  icon_go_forward: '',
  chat_system_prompt_modifier: '',
  helphub_chat_welcome_message: '',
  helphub_chat_avatar: '',
  in_bar_feedback: false,
  summon_hotkey_override: null,
  last_snippet_request: '',
  last_snippet_request_in_production: '',
  launcher_offset_x: 0,
  launcher_offset_y: 0,
  launcher_position: 'bottomRight',
  helphub_launcher_offset_x: 0,
  helphub_launcher_offset_y: 60,
  helphub_launcher_anchor: '',
  helphub_launcher_position: 'bottomRight',
  helphub_launcher_type: 'none',
  launcher_type: 'minimal',
  recommendations_type: undefined,
  resource_options: {},
  search_fuzzy_threshold: null,
  should_show_onboarding: false,
  show_launcher_recommendations: false,
  show_skin_editor: false,
  theme: {},
  end_user_hotkeys: false,
  end_user_shortcuts_enabled: false,
  end_user_recents_enabled: false,
  force_end_user_identity_verification: false,
  force_end_user_identity_verification_for_helphub: false,
  releases_available: false,
  releases_enabled: false,
  fallback_commands: [],
  event_attribute_block_list: [],
  tab_direction: 'horizontal',
  recommended_tab_enabled: false,
  recents_tab_enabled: false,
  recommended_tab_instruction: '',
  recents_tab_instruction: '',
  hotload_help_docs: false,
  slash_filters_enabled: true,
  recents_sort_key: null,
  recommended_sort_key: null,
  show_suggested_synonyms: false,
  has_hotloaded_help_docs: false,
  bar_enabled: true,
  helphub_enabled: false,
  helphub_ai_enabled: false,
  helphub_suggested_queries_enabled: false,
  helphub_continuations_enabled: false,
  helphub_chat_only_mode: false,
  helphub_chat_fallback_message: '',
  helphub_chat_fallback_actions: [],
  helphub_manual_suggested_queries: [],
  copilot_experiences_enabled: false,
  nudge_rate_limit: null,
  bar_hide_completed_nudges_questlists: false,
  nudge_rate_period: 'day',
  in_bar_doc_search: true,
  featured_item_cards: true,
  installed_at: null,
  integrations: {},
};

export const OrganizationV = t.intersection([OrganizationBaseV, OrganizationAdditionalV], 'Organization');

export const unknownOrganization: IOrganizationType = {
  id: '42424242',
  name: 'Unknown',
  created: '',
  ...defaults,
};
